@use '@angular/material' as mat;

$asphalt: #2B373F;
$soft-yellow: #FFF591 !default;
$soft-green: #BBCB9B;
$soft-orange: #DC7D48;
$light-grey: #E8E9EB;
$soft-brown: #CAB69E;

// Set Bootstrap theme according Material theme
$brand-primary: $asphalt;//mat.get-color-from-palette($app-primary) !default;
$brand-accent: $soft-green;//mat.get-color-from-palette($app-accent) !default;
$brand-info: $soft-green;//mat.get-color-from-palette($app-accent) !default;
$brand-success: $soft-green;//mat.get-color-from-palette($app-success) !default;
$brand-warning: $soft-orange;//mat.get-color-from-palette($app-warning) !default;
$brand-danger: #B93327;//mat.get-color-from-palette($app-danger) !default;
$brand-disabled: mat.get-color-from-palette($app-foreground, disabled) !default;
$brand-text: mat.get-color-from-palette($app-foreground, text) !default;
$brand-hover: mat.get-color-from-palette($app-background, hover) !default;
$brand-background: #fff;//mat.get-color-from-palette($app-background, background) !default;
$brand-default: $brand-primary !default;

$soft-yellow: #FFF591 !default;

$brand-yellow: $soft-yellow !default;
$brand-purple: mat.get-color-from-palette($app-purple) !default;
$brand-grey: $light-grey;//mat.get-color-from-palette($app-grey) !default;
$brand-brown: $soft-brown;//mat.get-color-from-palette($app-brown) !default;
$brand-blue-grey: $brand-grey;//mat.get-color-from-palette($app-blue-grey) !default;
$brand-deep-orange: $soft-orange;//mat.get-color-from-palette($app-deep-orange) !default;
$brand-lime: mat.get-color-from-palette($app-lime) !default;
$brand-light-green: $soft-green;//mat.get-color-from-palette($app-light-green) !default;
$brand-teal: mat.get-color-from-palette($app-teal) !default;
$brand-cyan: mat.get-color-from-palette($app-cyan) !default;
$brand-indigo: mat.get-color-from-palette($app-indigo) !default;
$brand-pink: mat.get-color-from-palette($app-pink) !default;


