.grecaptcha-badge {
  display: none;
}

.recaptcha-text {
  margin-top: 15px;
  padding: 0 5%;
  font-size: small;
  color: $gray-light;

  a {
    color: $brand-info;
  }
}