.mat-raised-button {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  min-width: 100px !important;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-menu-item {
  &:focus {
    outline: none;
  }
}

.mat-button-toggle-checked {
  background-color: $brand-primary;
  color: white !important;
}

.mat-menu-item .mat-icon {
  margin-right: 0 !important;
}

tbody {
  .mat-icon-button {
    background-color: $brand-primary !important;
    border-radius: 5px;
    margin-right: 5px;
    color: #fff !important;
  }
}

.mat-icon-button {
  width: 35px !important;
}

.mat-button-tab-close-only {
  right: 15px;
  position: fixed !important;
}

.nav-tabs {
  button {
    color: white !important;
  }

  .mat-button[disabled][disabled] {
    color: darken(white, 25%) !important;
  }
}

.logo-mini {
  a {
    color: white !important;
    padding-right: 5px !important;
  }
  @media screen and (max-width: 992px) {
    display: none !important;
  }
}

.button-refresh {
  min-width: 50px !important;
  min-height: 40px !important;
  padding: 0px 10px !important;
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}

.mat-icon {
  font-size: 1.4rem;

  &+span {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
  }
}

.mat-button-group {

  button {
    border-radius: 0;
    padding: 0;
  }

  button:first-child {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }

  ;

  button:last-child {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }

  button.selected-button {
    background-color: $brand-accent !important;

    :hover,
    :focus {
      background-color: $brand-accent !important;
    }
  }

  button.inactive-button {
    background-color: $brand-disabled !important;

    :hover,
    :focus {
      background-color: $brand-disabled !important;
    }
  }
}

.dropdown-button.mat-menu-panel {
  @extend .mat-menu-panel;
  max-width: 100%;
}
