@import "../core/cards";
// cards
@import "../core/cards/card-testimonials";
@import "../core/cards/card-profile";
@import "../core/cards/card-background";

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.14);
  border-radius: 20px;

  .table tr:first-child td {
    border-top: none;
  }

  .card-title {
    margin-top: 0;
    margin-bottom: 3px;
  }

  .card-body {
    position: relative;
    max-height: 90vh;
    & .mat-toolbar {
    }
    & > .mat-toolbar, .mat-toolbar-row {
      > button:first-child {
        margin-left: 0px;
      }
      > button:last-child {
        margin-right: 0px;
      }
    }

  }

  .card-logo {
    max-width: 5em;
  }

  .big-card-logo {
    height: auto;
    max-height: 200px;
    max-width: 200px;
    margin: 5px;
  }

  .card-disabled {
    background:rgba(255,255,255,0.8);
    padding: 0.25rem !important;
  }

  .card-header {
    z-index: 3 !important;

    .card-category {
      margin: 0;
    }

    &.card-header-text {
      display: inline-block;

      &:after {
        content: "";
        display: table;
      }
    }

    &.card-header-icon,
    &.card-header-text {
      i {
        width: 33px;
        height: 33px;
        text-align: center;
        line-height: 33px;
      }
      .card-title {
        color: $brand-text;
        font-weight: 400;
        font-size: 1.5rem;
      }
      h4 {
        font-weight: 300;
      }
    }

    &.card-header-tabs {
      .nav-tabs {
        background: transparent;
        padding: 0;
      }
      .nav-tabs-title {
        color: white;
        float: left;
        font-size: $font-size-h4;
        font-weight: 400;
        padding: 10px 10px 10px 0;
        line-height: 24px;
        margin: 0;
      }
      .nav-tabs-navigation {
        .btn-link {
          color: white !important;
        }
      }
    }
  }

  .card-actions {
    position: absolute;
    z-index: 1;
    top: -50px;
    width: calc(100% - 30px);
    left: 17px;
    right: 17px;
    text-align: center;

    .card-header {
      padding: 0;
      min-height: 160px;
    }

    .btn {
      padding-left: 12px;
      padding-right: 12px;
    }
    .fix-broken-card {
      position: absolute;
      top: -65px;
    }
  }

  .card-body + .card-footer,
  .card-footer {
    padding: 0;
    padding-top: 15px;
    margin: 0 15px 10px;
    border-radius: 0;
    justify-content: space-between;
    align-items: center;

    h6 {
      width: 100%;
    }

    .stats {
      color: $brand-text;
      font-size: 12px;
      line-height: 22px;

      .card-category {
        padding-top: 7px;
        padding-bottom: 7px;
        margin: 0;
      }

      .material-icons {
        position: relative;
        top: 4px;
        font-size: 16px;
      }
    }
  }
  [class*="card-header-"] {
    margin: 0px 15px 0;
    padding: 0;

    &:not(.card-header-icon):not(.card-header-text):not(.card-header-image) {
      border-radius: 0;
      padding: 10px 15px 10px 15px;
    }

    .card-icon,
    .card-text {
      border-radius: $border-radius-base;
      color: $brand-background;
      background-color: $brand-primary;
      padding: 15px;
      margin-top: -10px;
      margin-right: 15px;
      float: left;
    }

    .card-text {
      float: none;
      display: inline-block;
      margin-right: 0;

      .card-title {
        color: $brand-background;
        margin-top: 0;
      }
    }

    position: relative;

    .ct-chart {
      .card-title {
        color: $brand-background;
      }
      .card-category {
        margin-bottom: 0;
        color: rgba($brand-background, .62);
      }

      .ct-label {
        color: rgba($brand-background, .7);
      }
      .ct-grid {
        stroke: rgba(255, 255, 255, 0.2);
      }
      .ct-series-a .ct-point,
      .ct-series-a .ct-line,
      .ct-series-a .ct-bar,
      .ct-series-a .ct-slice-donut {
        stroke: rgba(255, 255, 255, .8);
      }
      .ct-series-a .ct-slice-pie,
      .ct-series-a .ct-area {
        fill: rgba(255, 255, 255, .4);
      }
      .ct-series-a .ct-bar {
        stroke-width: 10px;
      }
      .ct-point {
        stroke-width: 10px;
        stroke-linecap: round;
      }
      .ct-line {
        fill: none;
        stroke-width: 4px;
      }
    }
  }

  [data-header-animation="true"] {
    @include transform-translate-y(0);
    -webkit-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
    -moz-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
    -o-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
    -ms-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
    transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
  }

  &:hover {
    [data-header-animation="true"] {
      @include transform-translate-y(-50px);
    }
  }

  &.card-stats {
    background: rgba($brand-primary, 0.1);
    .card-header {
      &.card-header-icon,
      &.card-header-text {
        text-align: right;
      }

      .card-icon + .card-title,
      .card-icon + .card-category {
        padding-top: 10px;
      }

      .card-title,
      .card-category {
        margin: 0;
      }
      .card-category {
        margin-bottom: 0;
        margin-top: 0;

        &:not([class*="text-"]) {
          color: $brand-text;
          font-size: $font-paragraph;
        }
      }

      & + .card-footer {
        border-top: 1px solid #eee;
        margin-top: 20px;
      }

      i {
        font-size: 36px;
        line-height: 56px;
        width: 56px;
        height: 56px;
        text-align: center;
      }
    }

    .card-body {
      text-align: right;
    }
  }

  .map {
    height: 280px;
    border-radius: $border-radius-large;
    margin-top: 15px;

    &.map-big {
      height: 420px;
    }
  }

  .card-body.table-full-width {
    padding: 0;
  }

  .form {
    height: 100%;
  }
}
