// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.
@import "../../../../node_modules/bootstrap/scss/tooltip";

.tooltip {
  // @extend .tooltip;
  display: none;
  color: $brand-text;
  z-index: 10500; // bs index is too low for modal
  // opacity: 1;
  &.show {
    // @extend .show;
    display: block;
    
  }
}