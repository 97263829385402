$gray-lighter: rgba($brand-text, 0.12) !default;
$gray-light: #999 !default;
$gray-alpha: .54 !default;
$gray: #555 !default; // spec color
$brand-disabled: rgba($brand-text, 0.87) !default; // used for text color - others use grey-600 which is considerably lighter

$bmd-font-weight-base: 400;

// wondering if any of these could still be refactored out, but are definitely in use.
$bmd-inverse: rgba($brand-background, 1) !default;
$bmd-inverse-light: rgba($brand-background, 0.84) !default;
$bmd-inverse-lighter: rgba($brand-background, 0.54) !default;

$bmd-label-color: $brand-disabled !default;
$bmd-label-color-inner-focus: $brand-disabled !default; // e.g. radio label or text-muted not a control-label which is primary

$border-radius-base: 5px !default;
$border-radius-small: 2px !default;
$border-radius-large: 6px !default;
$border-radius-huge: 10px !default;
$border-radius-label: 12px !default;
$border-radius-extreme: 30px !default;

// Typography elements
$mdb-font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !default;
$mdb-text-color-light: $brand-background !default;
$mdb-text-color-light-hex: $brand-background !default; // for contrast function in inverse
$mdb-text-color-primary: unquote("rgba($brand-text, 0.87)") !default;
$mdb-text-color-primary-hex:$brand-text !default; // for contrast function in inverse
$icon-color: rgba(0, 0, 0, 0.5) !default;

$mdb-label-color: unquote("rgba($brand-text, 0.26)") !default;
$mdb-label-color-toggle-focus: unquote("rgba($brand-text, .54)") !default;
