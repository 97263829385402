.mat-checkbox-checked .mat-checkbox-background .mat-checkbox-indeterminate {
  background: $brand-primary !important;
  background-color: $soft-green!important;
}
.mat-checkbox-layout {
  margin-bottom: 3px;
  white-space: normal !important;
}

.mat-checkbox-layout .mat-checkbox-label {
  padding-top: 2px;
}

@keyframes checkbox-on {
  0% {
    box-shadow: 0 0 0 10px,
    10px -10px 0 10px,
    32px 0 0 20px,
    0px 32px 0 20px,
    -5px 5px 0 10px,
    15px 2px 0 11px;
  }
  50% {
    box-shadow: 0 0 0 10px,
    10px -10px 0 10px,
    32px 0 0 20px,
    0px 32px 0 20px,
    -5px 5px 0 10px,
    20px 2px 0 11px;
  }
  100% {
    box-shadow: 0 0 0 10px,
    10px -10px 0 10px,
    32px 0 0 20px,
    0px 32px 0 20px,
    -5px 5px 0 10px,
    20px -12px 0 11px;
  }
}

@keyframes rippleOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}

@keyframes rippleOff {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
