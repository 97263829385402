.navbar {
  border: 0;
  border-radius: $border-radius-base;
  padding: 0.625rem 0;
  margin-bottom: 20px;
  @include navbar-colors($brand-background, $navbar-color);

  &.fixed-top {
    border-radius: 0;
  }

  .navbar-nav {
    .nav-item .nav-link {
      position: relative;
      color: $brand-text;
      padding: $padding-general-x;
      font-weight: $font-weight-default;
      font-size: $mdb-btn-font-size-base;
      text-transform: uppercase;
      border-radius: $border-radius-base;
      line-height: 20px;

      &:not(.btn-just-icon) .fa {
        position: relative;
        top: 2px;
        margin-top: -4px;
        margin-right: 4px;
      }

      & .material-icons,
      & .fa {
        font-size: $font-size-lg;
        max-width: 24px;
        margin-top: -1.1em;
      }

      &:not(.btn) .material-icons {
        margin-top: -7px;
        top: 3px;
        position: relative;
        margin-right: 3px;
      }

      &.profile-photo {
        padding: 0;
        margin: 0 3px;

        &:after {
          display: none;
        }

        & .profile-photo-small {
          height: 40px;
          width: 40px;
        }

        .ripple-container {
          border-radius: 50%;
        }
      }
    }

    .dropdown-menu-right {
      transform-origin: 100% 0;
    }

    .nav-item.active .nav-link {
      &,
      &:hover,
      &:focus {
        color: $brand-text;
        background-color: #ececec;//$brand-background;
      }
    }
  }

  .navbar-toggler {
    cursor: pointer;
    color:#fff!important;

    .navbar-toggler-icon {
      width: 22px;
      height: 2px;
      vertical-align: middle;
      outline: 0;
      display: block;
      border-radius: 1px;

      & + .navbar-toggler-icon {
        margin-top: 4px;
      }
    }
  }

  &.navbar-absolute {
    position: absolute;
    width: 100%;
    padding-top: 10px;
  }

  &.navbar-auth {
    z-index: 5;
  }

  .navbar-wrapper {
    display: inline-flex;
    align-items: center;
  }

  .navbar-logo {
    width: 100%;
    max-width: 225px;
    height: auto;
  }

  // give correct size to ripple container
  .navbar-brand {
    position: relative;
    color: #000;
    font-size: $navbar-brand-font-size - 0.125;
    line-height: 30px;
    padding: 0;
    font-weight: 500;
    margin-left: 1rem;
  }

  &.bg-primary {
    @include navbar-colors($bg-primary, $brand-background);
  }
  &.bg-info {
    @include navbar-colors($bg-info, $brand-background);
  }

  &.bg-warning {
    @include navbar-colors($bg-warning, $brand-background);
  }

  &.bg-rose {
    @include navbar-colors($bg-rose, $brand-background);
  }

  &.bg-danger {
    @include navbar-colors($bg-danger, $brand-background);
  }

  &.bg-success {
    @include navbar-colors($bg-success, $brand-background);
  }
  &.bg-dark {
    @include navbar-colors($brand-disabled, $brand-background);
  }

  &.navbar-transparent {
    background-color: transparent !important;
    opacity: 0.9;
    box-shadow: none;
    padding-top: 25px;
  }

  .notification {
    position: absolute;
    top: 5px;
    border: 1px solid $brand-background;
    right: 10px;
    font-size: 9px;
    background: $brand-danger;
    color: $brand-text;
    min-width: 20px;
    padding: 0px 5px;
    height: 20px;
    border-radius: 10px;
    text-align: center;
    line-height: 19px;
    vertical-align: middle;
    display: block;
  }
}

.main-panel .navbar {
  background-color: $brand-primary!important;
  border-radius: 0!important;
  opacity: 1!important;

  @media screen and (min-width: 992px) {
    display: none;
  }
}

// for demo
#navbar .navbar {
  border-radius: 0;
}
