// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.
@mixin shadow-alert-color($color) {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
  0 7px 10px -5px rgba($color, 0.4)
}

.alert {
  border: 0;
  border-radius: 3px;
  position: relative;
  padding: 20px 15px;
  line-height: 20px;

  b {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    font-size: $font-size-small;
  }
  // SASS conversion note: please mirror any content change in _mixins-shared.scss alert-variations-content
  @include alert-variations(unquote(".alert"), unquote(""), $mdb-text-color-light);

  &-info, &-danger, &-warning, &-success, &-rose {
    color: $mdb-text-color-light;
  }

  &-default {
    a, .alert-link {
      color: $mdb-text-color-primary;
    }
  }

  span {
    display: block;
    max-width: 89%;
  }

  &.alert-danger {
    @include shadow-alert-color($brand-danger);
    @include alert-icon-color($brand-danger);
  }
  &.alert-warning {
    @include shadow-alert-color($brand-warning);
    @include alert-icon-color($brand-warning);
  }
  &.alert-success {
    @include shadow-alert-color($brand-success);
    @include alert-icon-color($brand-success);
  }
  &.alert-info {
    @include shadow-alert-color($brand-info);
    @include alert-icon-color($brand-info);
  }
  &.alert-primary {
    @include shadow-alert-color($brand-primary);
    @include alert-icon-color($brand-primary);
  }
  &.alert-rose {
    @include shadow-alert-color($brand-info);
    @include alert-icon-color($brand-info);
  }

  &.alert-with-icon {
    margin-top: 43px;
    padding-left: 66px;

    i[data-notify="icon"] {
      display: block;
      left: 15px;
      position: absolute;
      margin-top: -39px;
      font-size: 20px;
      background-color: $brand-background;
      padding: 9px;
      border-radius: 50%;
      max-width: 38px;
      @include shadow-big();
    }
  }

  .close {
    line-height: .5;
    background: transparent;
    border: 0;
    i {
      color: white;
      font-size: 17px;
    }
  }

  i[data-notify="icon"] {
    display: none;
  }

  .alert-icon {
    display: block;
    float: left;
    margin-right: $margin-base;

    i {
      margin-top: -7px;
      top: 5px;
      position: relative;
    }
  }

  [data-notify="dismiss"] {
    // margin-right: 5px;
  }
}
